//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TechnologyAndInformationSystemModel } from "src/app/models/technologyandinformationsystem.model";
import { UserSessionModel } from "src/app/models/usersession.model";
import { PwaService } from "src/app/services/pwa.service";
import { SessionService } from "src/app/services/session.service";
import { TechnologyAndInformationSystemService } from "src/app/services/technologyandinformationsystem.service";
import { ENUM_LETTER_ROLE } from "../../constants/enum.constant";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";


//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanNavigation: boolean | null;
	public _booleanNavigationItem: boolean | null;
	public _booleanNavigationItem2: boolean | null;
	public _booleanNavigationEmaterai: boolean | null;
	public _booleanNavigationItemForReimbursement: boolean | null;
	public _booleanNavigationItemForApproval: boolean | null;
	public _booleanNavigationItemLegal: boolean | null;
	public _booleanNavigationItemDashboard: boolean | null;
	public _booleanNavigationItemEpolicyMonitoring: boolean | null;
	public _booleanNavigationItemGCGOnline: boolean | null;
	public _booleanNavigationCodeOfEthics: boolean | null;
	public _booleanNavigationAnnualDisclosure: boolean | null;
	public _booleanNavigationItemOperational: boolean | null;
	public _booleanNavigationItemBrinsEditorial: boolean | null;
	public _booleanNavigationApuPPT: boolean | null;
	public _booleanNavigationBrinspro: boolean | null;

	public _enumLetterRole = ENUM_LETTER_ROLE;

	public _booleanNavigationItemRCSA: boolean | null;

	public _stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceTechnologyAndInformationSystem: TechnologyAndInformationSystemService, serviceSession: SessionService, router: Router, public _servicePWA: PwaService)
	{
		super(serviceSession, router);

		this._booleanNavigation = null;
		this._booleanNavigationItem = null;
		this._booleanNavigationItem2 = null;
		this._booleanNavigationEmaterai = null;
		this._booleanNavigationItemForReimbursement = null;
		this._booleanNavigationItemForApproval = null;
		this._booleanNavigationItemRCSA = null;
		this._booleanNavigationItemLegal = null;
		this._booleanNavigationItemDashboard = null;
		this._booleanNavigationItemEpolicyMonitoring = null;
		this._booleanNavigationItemGCGOnline = null;
		this._booleanNavigationCodeOfEthics = null;
		this._booleanNavigationAnnualDisclosure = null;
		this._booleanNavigationItemOperational = null;
		this._booleanNavigationItemBrinsEditorial = null;
		this._booleanNavigationApuPPT = null;
		this._booleanNavigationBrinspro = null;
	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{
	}

	//#endregion


	//#region FUNCTION

	toggleNavigation(): void
	{
		this._booleanNavigation = !this._booleanNavigation;
		this._booleanNavigationItem = false;
		this._booleanNavigationItem2 = false;
		this._booleanNavigationEmaterai = false;
		this._booleanNavigationItemForReimbursement = false;
		this._booleanNavigationItemForApproval = false;
		this._booleanNavigationItemGCGOnline = false;
		this._booleanNavigationCodeOfEthics = false;
		this._booleanNavigationAnnualDisclosure = false;
		this._booleanNavigationApuPPT = false;
	}

	//#endregion


	//#region WEB SERVICE

	callSignOut(): void
	{
		const componentCurrent: HomeComponent = this;
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

		if (modelUserSession != null)
		{
			this._serviceTechnologyAndInformationSystem.signOut
			(
				{
					success(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
							{
								componentCurrent.signOut();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						});
					},
					fail(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSignOut(); });
					},
					signOut(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
					}
				}, new TechnologyAndInformationSystemModel()
			);
		}
		else
		{
			componentCurrent.signOut();
		}
	}

	//#endregion


	//#region NAVIGATION

	goToUtilityDashboard(): void
	{
		this._router.navigate(["home", "dashboard", "utilitydashboard"]);
		this.toggleNavigation();
	}

	goToDigitalDashboard(): void
	{
		this._router.navigate(["home", "dashboard", "digitaldashboard"]);
		this.toggleNavigation();
	}

	goToAbout(): void
	{
		this._router.navigate(["home", "about"]);
		this.toggleNavigation();
	}

	goToTicketReceived(): void
	{
		this._router.navigate(["home", "ticket", "received", "list"]);
		this.toggleNavigation();
	}

	goToTicketSent(): void
	{
		this._router.navigate(["home", "ticket", "sent", "list"]);
		this.toggleNavigation();
	}

	goToTicketReport(): void
	{
		this._router.navigate(["home", "ticket", "report"]);
		this.toggleNavigation();
	}

	goToTicketSatisfactionReport(): void
	{
		this._router.navigate(["home", "ticket", "satisfactionreport"]);
		this.toggleNavigation();
	}

	goToEmaterai(): void
	{
		this._router.navigate(["home", "ematerai", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForMaker(): void
	{
		this._router.navigate(["home", "ematerai", "maker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForChecker(): void
	{
		this._router.navigate(["home", "ematerai", "checker", "list"]);
		this.toggleNavigation();
	}

	goToEmateraiForSigner(): void
	{
		this._router.navigate(["home", "ematerai", "signer", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralChecker(): void
	{
		this._router.navigate(["home", "reimbursement" ,"approval", "checker", "list"]);
		this.toggleNavigation();
	}

	goToReimbursementGeneralSigner(): void
	{
		this._router.navigate(["home", "reimbursement" ,"approval", "signer", "list"]);
		this.toggleNavigation();
	}

	goToAssessment(): void
	{
		this._router.navigate(["home", "rcsa", "assessment", "list"]);
		this.toggleNavigation();
	}

	goToApproval(): void
	{
		this._router.navigate(["home", "rcsa", "approval", "list"]);
		this.toggleNavigation();
	}

	goToReport(): void
	{
		this._router.navigate(["home", "rcsa", "report", "list"]);
		this.toggleNavigation();
	}

	goToRiskIssueRequest(): void
	{
		this._router.navigate(["home", "rcsa", "riskissuerequest", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationChecker(): void
	{
		this._router.navigate(["home", "digitalization", "checker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationMaker(): void
	{
		this._router.navigate(["home", "digitalization", "maker", "list"]);
		this.toggleNavigation();
	}

	goToDigitalizationSigner(): void
	{
		this._router.navigate(["home", "digitalization", "signer", "list"]);
		this.toggleNavigation();
	}

	goToEpolicyFormation(): void
	{
		this._router.navigate(["home", "operational", "epolicymonitoring", "policyprinting"]);
		this.toggleNavigation();
	}

	goToEpolicyDelivery(): void
	{
		this._router.navigate(["home", "operational", "epolicymonitoring", "policydelivery"]);
		this.toggleNavigation();
	}

	goToEpolicyRealtime(): void
	{
		this._router.navigate(["home", "operational", "epolicymonitoring", "epolicyrealtime"]);
		this.toggleNavigation();
	}

	goToGCGOnlineProfile(): void
	{
		this._router.navigate(["home", "gcgonline", "profile"]);
		this.toggleNavigation();
	}

	goToGCGOnlineGratification(): void
	{
		this._router.navigate(["home", "gcgonline", "gratification", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineIntegrityPact(): void
	{
		this._router.navigate(["home", "gcgonline", "integritypact", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthics(): void
	{
		this._router.navigate(["home", "gcgonline", "codeofethics", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineCodeOfEthicsApproval(): void
	{
		if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "checker", "list"]);
			this.toggleNavigation();
		}
		else if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "codeofethics", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToGCGOnlineAnnualDisclosure(): void
	{
		this._router.navigate(["home", "gcgonline", "annualdisclosure", "list"]);
		this.toggleNavigation();
	}

	goToGCGOnlineAnnualDisclosureApproval(): void
	{
		if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGCHECKER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "checker", "list"]);
			this.toggleNavigation();
		}
		else if(this._modelUserSignIn.checkGroupAccessGCGRole(StringConstant.STRING_ACCESS_USER_GCGSIGNER))
		{
			this._router.navigate(["home", "gcgonline", "annualdisclosure", "signer", "list"]);
			this.toggleNavigation();
		}
		else
		{
		}
	}

	goToBrinsEditorialMonitoring(): void
	{
		this._router.navigate(["home", "operational", "brinseditorialmonitoring", "brinseditorial"]);
	}

	goToAPUPPTCustomer(): void
	{
		this._router.navigate(["home", "apu-ppt", "customerdata"]);
		this.toggleNavigation();
	}

	goToRiskBasedAssessment(): void
	{
		this._router.navigate(["home", "apu-ppt", "riskbasedassessment"]);
		this.toggleNavigation();
	}

	goToTransaction(): void
	{
		this._router.navigate(["home", "apu-ppt", "transaction", "list"]);
		this.toggleNavigation();
	}

	goProjectManagementDashboard(): void
	{
		this._router.navigate(["home", "brinspro", "dashboard"]);
		this.toggleNavigation();
	}

	goToProjectManagementProject(): void
	{
		this._router.navigate(["home", "brinspro", "project", "list"]);
		this.toggleNavigation();
	}

	goToProjectManagementDocument(): void
	{
		this._router.navigate(["home", "brinspro", "document", "list"]);
		this.toggleNavigation();
	}

	goToDashboardMpnitoringGCG(): void
	{
		this._router.navigate(["home", "dashboard", "gcgmonitoring"]);
		this.toggleNavigation();
	}

	goToDashboardMpnitoringSLP(): void
	{
		this._router.navigate(["home", "dashboard", "slpmonitoring"]);
		this.toggleNavigation();
	}

	//#endregion

}

//#endregion
