/* eslint-disable id-blacklist */
//#region STRING

// eslint-disable-next-line no-unused-vars
interface String
{
	convertToUTF8Bytes(): Array<number>;
	convertToUTF16Bytes(): Array<string>;
	convertToCurrency(): string;
	convertBase64ToUTF8Bytes(): Array<number>;
	validateEmpty(): boolean;
	convertToFileNamePNG(): string;
	convertRGBToHex(): string;
	getFileExtension(): string;
}

/*
	convertNumberToCurrency - START
	Description : Convert date of month with date.
	Author : Komang Suryadana and Ibrahim Aziz.
	Created on  : Wed, 10 October 2018.					Updated on : Thursday, 27 February 2020.
	Created by  : Komang Suryadana.                    	Updated by : Ibrahim Aziz.
	Version : 1.0:2.
	Reference : https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
*/

String.prototype.convertToCurrency = function(): string
{
	if (this)
	{
		const arrayStringNumber: Array<string> = this.split(".");

		if (arrayStringNumber.length > 1)
		{
			return `${arrayStringNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${arrayStringNumber[1].slice(0, 2)}`;
		}
		else
		{
			return this.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		}
	}
	else
	{
		return "";
	}
};

/* convertNumberToCurrency - END */

/*
	convertStringToUTF8Bytes - START
	Description : This is function to convert string to utf 8 array of bytes.
	Refrence: https://stackoverflow.com/questions/18729405/how-to-convert-utf8-string-to-byte-array
	Author : Ibrahim Aziz.
	Created on : Friday, 19 April 2019.		Updated on : Tuesday, 5 January 2021.
	Created by : Ibrahim Aziz.				Updated by : Ibrahim Aziz.
	Version : 1.0:3.
*/

String.prototype.convertToUTF8Bytes = function(): Array<number>
{
	const stringUTF8: string = unescape(encodeURIComponent(this as string));
	const arrayBytes: Array<number> = [];

	for (let numberIndex: number = 0; numberIndex < stringUTF8.length; numberIndex++)
	{
		arrayBytes.push(stringUTF8.charCodeAt(numberIndex));
	}

	return arrayBytes;
};

/* convertStringToUTF8Bytes - END */

/*
	convertStringToUTF8Bytes - START
	Description : This is function to convert string to utf 8 array of bytes.
	Refrence: https://stackoverflow.com/questions/18729405/how-to-convert-utf8-string-to-byte-array
	Author : Ibrahim Aziz.
	Created on : Friday, 19 April 2019.		Updated on : Tuesday, 5 January 2021.
	Created by : Ibrahim Aziz.				Updated by : Ibrahim Aziz.
	Version : 1.0:3.
*/

String.prototype.convertToUTF16Bytes = function(): Array<string>
{
	const stringUTF8: string = unescape(encodeURIComponent(this as string));
	const arrayBytes: Array<string> = [];

	for (let numberIndex: number = 0; numberIndex < stringUTF8.length; numberIndex++)
	{
		arrayBytes.push(Number(stringUTF8.charCodeAt(numberIndex)).toString(16).toUpperCase());
	}

	return arrayBytes;
};

/* convertStringToUTF8Bytes - END */

/*
	convertBase64ToUTF8Bytes - START
	Description : This is function to convert base 64 string to utf 8 array of bytes.
	Refrence: https://stackoverflow.com/questions/18729405/how-to-convert-utf8-string-to-byte-array
	Author : Ibrahim Aziz.
	Created on : Friday, 19 April 2019.		Updated on : Tuesday, 5 January 2021.
	Created by : Ibrahim Aziz.				Updated by : Ibrahim Aziz.
	Version : 1.0:3.
*/

String.prototype.convertBase64ToUTF8Bytes = function(): Array<number>
{
	const stringDecoded: string = window.atob(this as string);
	const numberDecodedLength: number = stringDecoded.length;
	const arrayUINT8Bytes: Uint8Array = new Uint8Array(numberDecodedLength);

	for (let numberIndex: number = 0; numberIndex < numberDecodedLength; numberIndex++)
	{
		arrayUINT8Bytes[numberIndex] = stringDecoded.charCodeAt(numberIndex);
	}

	const arrayBytes: Array<number> = Array.from(new Uint8Array(arrayUINT8Bytes.buffer));

	return arrayBytes;
};

/* convertBase64ToUTF8Bytes - END */

/*
	validateEmpty - START
	Description : This is function to convert base 64 string to utf 8 array of bytes.
	Author : Ibrahim Aziz.
	Created on : Friday, 19 April 2019.		Updated on : Thursday, 27 February 2020.
	Created by : Ibrahim Aziz.				Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

String.prototype.validateEmpty = function(): boolean
{
	if (this === null || this === undefined || this === "")
	{
		return false;
	}
	else
	{
		return true;
	}
};

/* validateEmpty - END */

/*
	convertToFileNamePNG - START
	Description : Generate array of date with duration, can be negative duration.
	Author : Ibrahim Aziz.
	Created on  : Friday, 5 June 2020.				Updated on : Friday, 5 June 2020.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

String.prototype.convertToFileNamePNG = function(): string
{
	return this + ".png";
};

/* convertToFileNamePNG - END */

/*
	getFileExtension - START
	Description : Generate array of date with duration, can be negative duration.
	Author : Ibrahim Aziz.
	Created on  : Friday, 11 June 2021.				Updated on : Friday, 11 June 2021.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

String.prototype.getFileExtension = function(): string
{
	const arrayStringFormat: string[] = this.split(".");

	if (arrayStringFormat != null || arrayStringFormat !== undefined)
	{
		if (arrayStringFormat.length > 1)
		{
			return arrayStringFormat[arrayStringFormat.length - 1];
		}
		else if (arrayStringFormat.length === 0)
		{
			return arrayStringFormat[0];
		}
		else
		{
			return this.toString();
		}
	}
	else
	{
		return this.toString();
	}
};

/* getFileExtension - END */

//#endregion


//#region NUMBER

// eslint-disable-next-line no-unused-vars
interface Number
{
	convertToCurrency(): string;
	convertToHex(): string;
}

/*
	convertNumberToCurrency - START
	Description : Convert date of month with date.
	Author : Komang Suryadana and Ibrahim Aziz.
	Created on  : Wed, 10 October 2018.					Updated on : Thursday, 27 February 2020.
	Created by  : Komang Suryadana.                    	Updated by : Ibrahim Aziz.
	Version : 1.0:2.
	Reference : https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
*/

Number.prototype.convertToCurrency = function(): string
{
	if (this)
	{
		const stringNumber: string = this.toString();

		return stringNumber.convertToCurrency();
	}
	else
	{
		return "";
	}
};

/* convertNumberToCurrency - END */

/*
	convertNumberToHex - START
	Description : Convert red, green and blue color to hex.
	Author : Komang Suryadana and Ibrahim Aziz.
	Created on  : Tuesday, 21 March 2021.					Updated on : Tuesday, 21 March 2021.
	Created by  : Ibrahim Aziz.                    		Updated by : Ibrahim Aziz.
	Version : 1.0:2.
	Reference : https://www.w3docs.com/snippets/javascript/how-to-convert-rgb-to-hex-and-vice-versa.html
*/

Number.prototype.convertToHex = function(): string
{
	const stringHex: string = this.toString(16);

	return stringHex.length === 1 ? "0" + stringHex : stringHex;
};

/* convertToHex - END */

//#endregion


//#region DATE

interface Date
{
	convertForDisplayShort(): string;
	convertToISO8601(): Date;
	convertToStringISO8601(): string;
	generateDateWithDuration(numberDuration: number): Array<Date>;
	generateDateToday(): Date;
	generateFirstofDateMonth(): Date;
	generateEndofDateMonth(): Date;
	getDateFromFormWithoutTime(date: Date): Date;
	generateJanuaryThisYear(): Date;
	clearUTC(): Date;
	generateDateAgeMinimum(): Date;
	convertToISO8601String(): string;
	initiateWithoutUTC(): Date;
	calculateDayDifference(date: Date): number;
}

/*
	convertDateToForm - START
	Description : Convert date to date form yyyy-mm-dd.
	Author : Ibrahim Aziz.
	Created on  : Friday, 14 December 2018.			Updated on : Thursday, 27 February 2020.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

Date.prototype.convertForDisplayShort = function(): string
{
	let stringMonth: string = (this.getMonth() + 1).toString();

	if (stringMonth.length === 1)
	{
		stringMonth = `0${stringMonth}`;
	}
	else
	{

	}

	return `${this.getDate()}-${stringMonth}-${this.getFullYear()}`;
};

/* convertDateToForm - END */

/*
	convertToISO8601 - START
	Description : Convert date to date form yyyy-mm-ddThh:mm:ss.
	Author : Ibrahim Aziz.
	Created on  : Friday, 14 December 2018.			Updated on : Thursday, 27 February 2020.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:3.
*/

Date.prototype.convertToISO8601 = function(): Date
{
	const stringDate: string = this.convertToISO8601String();

	return new Date(stringDate);
};

/* convertDateToISO8601 - END */

/*
	convertToISO8601String - START
	Description : Convert date to date form yyyy-mm-ddThh:mm:ss.
	Author : Ibrahim Aziz.
	Created on  : Friday, 14 December 2018.			Updated on : Tuesday, 5 January 2021.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:3.
*/

Date.prototype.convertToStringISO8601 = function(): string
{
	let stringMonth: string = (this.getMonth() + 1).toString();

	if (stringMonth.length === 1)
	{
		stringMonth = `0${stringMonth}`;
	}
	else
	{

	}

	let stringHours: string = "00";

	if (this.getHours() == null || this.getHours() === undefined || this.getHours() === 0)
	{

	}
	else
	{
		stringHours = this.getHours().toString();

		if (stringHours.length === 1)
		{
			stringHours = `0${stringHours}`;
		}
		else
		{

		}
	}

	let stringMinutes: string = "00";

	if (this.getMinutes() == null || this.getMinutes() === undefined || this.getMinutes() === 0)
	{

	}
	else
	{
		stringMinutes = this.getMinutes().toString();

		if (stringMinutes.length === 1)
		{
			stringMinutes = `0${stringMinutes}`;
		}
		else
		{

		}
	}

	let stringSeconds: string = "00";

	if (this.getSeconds() == null || this.getSeconds() === undefined || this.getSeconds() === 0)
	{

	}
	else
	{
		stringSeconds = this.getSeconds().toString();

		if (stringSeconds.length === 1)
		{
			stringSeconds = `0${stringSeconds}`;
		}
		else
		{

		}
	}

	const stringDate: string = `${this.getFullYear()}-${stringMonth}-${this.getDate()}T${stringHours}:${stringMinutes}:${stringSeconds}Z`;

	return stringDate;
};

/* convertDateToISO8601String - END */

/*
	generateDateWithDuration - START
	Description : Generate array of date with duration, can be negative duration.
	Reference : https://stackoverflow.com/questions/19910161/javascript-calculating-date-from-today-date-to-7-days-before.
	Author : Ibrahim Aziz.
	Created on  : Saturday, 30 May 2020.			Updated on : Saturday, 30 May 2020.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

Date.prototype.generateDateWithDuration = (numberDuration: number): Array<Date> =>
{
	let date: Date;

	const arrayDate: Array<Date> = [];

	if (numberDuration > 0)
	{
		for (let numberIndex: number = 0; numberIndex < numberDuration; numberIndex++)
		{
			date = new Date();
			date.setDate(date.getDate() + numberIndex);
			arrayDate.push(date);
		}

		return arrayDate;
	}
	else if (numberDuration < 0)
	{
		for (let numberIndex: number = 0; numberIndex > numberDuration; numberIndex--)
		{
			date = new Date();
			date.setDate(date.getDate() + numberIndex);
			arrayDate.push(date);
		}

		return arrayDate;
	}
	else
	{
		return arrayDate;
	}
};

/* generateDateWithDuration - END */

/*
	generateDateToday - START
	Description : Generate array of date with duration, can be negative duration.
	Author : Ibrahim Aziz.
	Created on  : Wednesday, 17 June 2020.			Updated on : Tuesday, 5 January 2021.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

Date.prototype.generateDateToday = function(): Date
{
	let numberUTC: number = this.getHours() - this.getUTCHours();

	if (numberUTC < 0)
	{
		numberUTC = 24 + numberUTC;
	}
	else
	{

	}

	const date: Date = new Date();
	date.setHours(numberUTC);
	date.setMinutes(0);
	date.setSeconds(0);
	date.setMilliseconds(0);

	return date;
};

/* generateDateToday - END */

/*
	generateFirstofDateMonth - START
	Description : Generate array of date with duration, can be negative duration.
	Author : Ibrahim Aziz.
	Created on  : Wednesday, 17 June 2020.			Updated on : Tuesday, 5 January 2021.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

Date.prototype.generateFirstofDateMonth = function(): Date
{
	return new Date(this.getFullYear(),this.getMonth(),1);
};

/* generateFirstofDateMonth - END */

/*
	generateEndofDateMonth - START
	Description : Generate array of date with duration, can be negative duration.
	Author : Ibrahim Aziz.
	Created on  : Wednesday, 17 June 2020.			Updated on : Tuesday, 5 January 2021.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

Date.prototype.generateEndofDateMonth = function(): Date
{
	return new Date(this.getFullYear(),this.getMonth()+1,0);
};

/* generateEndofDateMonth - END */

/*
	getDateFromFormWithoutTime - START
	Description : Generate array of date with duration, can be negative duration.
	Author : Ibrahim Aziz.
	Created on  : Wednesday, 17 June 2020.			Updated on : Wednesday, 17 June 2020.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

Date.prototype.getDateFromFormWithoutTime = (stringDate: Date): Date =>
{
	const dateResult: Date = new Date(stringDate + "T00:00:00Z");

	return dateResult;
};

/* getDateFromFormWithoutTime - END */

/*
	clearUTC - START
	Description : Generate array of date with duration, can be negative duration.
	Author : Ibrahim Aziz.
	Created on  : Wednesday, 17 June 2020.			Updated on : Tuesday, 5 January 2020.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

Date.prototype.clearUTC = function(): Date
{
	let numberUTC: number = this.getHours() - this.getUTCHours();

	if (numberUTC < 0)
	{
		numberUTC = 24 + numberUTC;
	}
	else
	{

	}

	const dateResult: Date = this;
	dateResult.setHours(numberUTC);
	dateResult.setMinutes(0);
	dateResult.setSeconds(0);
	dateResult.setMilliseconds(0);

	return dateResult;
};

/* clearUTC - END */

/*
	initiateWithoutUTC - START
	Description : Generate array of date with duration, can be negative duration .
	Author : Ibrahim Aziz.
	Created on  : Wednesday, 17 June 2020.			Updated on : Tuesday, 5 January 2020.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

Date.prototype.initiateWithoutUTC = function(): Date
{
	let numberUTC: number = this.getHours() - this.getUTCHours();

	if (numberUTC < 0)
	{
		numberUTC = 24 + numberUTC;
	}
	else
	{

	}

	const dateResult: Date = this;

	dateResult.setHours(dateResult.getHours() - numberUTC);

	return dateResult;
};

/* initiateWithoutUTC - END */

/*
	generateDateToday - START
	Description : Generate array of date with duration, can be negative duration.
	Author : Ibrahim Aziz.
	Created on  : Wednesday, 17 June 2020.			Updated on : Tuesday, 5 January 2021.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

Date.prototype.generateDateAgeMinimum = function(): Date
{
	let numberUTC: number = this.getHours() - this.getUTCHours();

	if (numberUTC < 0)
	{
		numberUTC = 24 + numberUTC;
	}
	else
	{

	}

	const date: Date = new Date();
	date.setHours(numberUTC);
	date.setMinutes(0);
	date.setSeconds(0);
	date.setMilliseconds(0);
	date.setFullYear(date.getFullYear() - 17);

	return date;
};

/* generateDateToday - END */

/*
	calculateDayDifference - START
	Description : Calculate day difference between to dates.
	Author : Ibrahim Aziz.
	Created on  : Monday, 29 March 2021.			Updated on : Monday, 29 March 2021.
	Created by  : Ibrahim Aziz.						Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

Date.prototype.calculateDayDifference = function(date: Date): number
{
	const numberDate: number = this.getTime() - date.getTime();
	return Math.round(numberDate / 86400000);
};

/* calculateDayDifference - END */


/*
	generateJanuaryThisYear - START
	Description : Generate start date with 1st january this year.
	Author : Abdullah Fahmi.
	Created on  : Wednesday, 8 February 2023.		Updated on : Wednesday, 8 February 2023.
	Created by  : Abdullah Fahmi.					Updated by : Abdullah Fahmi.
	Version : 1.0:1.
*/

Date.prototype.generateJanuaryThisYear = function(): Date
{
	let numberUTC: number = this.getHours() - this.getUTCHours();

	if (numberUTC < 0)
	{
		numberUTC = 24 + numberUTC;
	}
	else
	{

	}

	const stringThisYear = new Date().getFullYear().toString();
	const date: Date = new Date(stringThisYear+"-01-01");
	date.setHours(numberUTC);
	date.setMinutes(0);
	date.setSeconds(0);
	date.setMilliseconds(0);

	return date;
};

/* generateJanuaryThisYear - END */

//#endregion


//#region ARRAY

// eslint-disable-next-line no-unused-vars
interface Array<T>
{
	convertUTF8BytesToBase64(): string;
}

/*
	convertUTF8BytesToBase64 - START
	Description : This is function to convert array of UTF 8 bytes to base 64.
	Refrence: https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string
	Author : Ibrahim Aziz.
	Created on : Friday, 19 April 2019.		Updated on : Thursday, 27 February 2020.
	Created by : Ibrahim Aziz.				Updated by : Ibrahim Aziz.
	Version : 1.0:2.
*/

Array.prototype.convertUTF8BytesToBase64 = function(): string
{
	let stringBinary: string = "";
	const arrayUInt8Bytes: Uint8Array = new Uint8Array(this);
	const numberArrayUInt8BytesLength: number = arrayUInt8Bytes.byteLength;

	for (let numberIndex = 0; numberIndex < numberArrayUInt8BytesLength; numberIndex++)
	{
		stringBinary += String.fromCharCode(arrayUInt8Bytes[numberIndex]);
	}

	return window.btoa(stringBinary);
};

/* convertUTF8BytesToBase64 - END */

// eslint-disable-next-line no-unused-vars
interface ArrayBuffer
{
	convertUTF8BytesToString(): string;
}

/*
	convertUTF8BytesToString - START
	Description : This is function to convert array of bytes to string.
	Refrence:
		https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string,
		https://stackoverflow.com/questions/38432611/converting-arraybuffer-to-string-maximum-call-stack-size-exceeded,
		https://stackoverflow.com/questions/8936984/uint8array-to-string-in-javascript
	Author : Ibrahim Aziz.
	Created on : Friday, 19 April 2019.		Updated on : Thursday, 27 February 2020.
	Created by : Ibrahim Aziz.				Updated by : Ibrahim Aziz.
	Version : 1.0:3.
*/

ArrayBuffer.prototype.convertUTF8BytesToString = function(): string
{
	return new TextDecoder("utf-8").decode(this);

	// return String.fromCharCode.apply(String, new Uint8Array(this));
};

/* convertUTF8BytesToString - END */

//#endregion


//#region File

// eslint-disable-next-line no-unused-vars
interface File
{
	convertToBase64(success: (stringBase64: string) => void, error: (errorRead: ProgressEvent<FileReader>) => void): void;
}

/*
	convertToBase64 - START
	Description : This is function to convert array of UTF 8 bytes to base 64.
	Refrence: https://stackoverflow.com/questions/9267899/arraybuffer-to-base64-encoded-string
	Author : Ibrahim Aziz.
	Created on : Friday, 19 April 2019.		Updated on : Thursday, 27 February 2020.
	Created by : Ibrahim Aziz.				Updated by : Ibrahim Aziz.
	Version : 1.0:1.
*/

File.prototype.convertToBase64 = function(success: (stringBase64: string) => void, error: (errorRead: ProgressEvent<FileReader>) => void): void
{
	const readerFile = new FileReader();
	readerFile.readAsDataURL(this);
	readerFile.onload = function()
	{
		if (readerFile.result != null)
		{
			if (readerFile.result instanceof ArrayBuffer)
			{
				success(readerFile.result.convertUTF8BytesToString());
			}
			else
			{
				success(readerFile.result);
			}
		}
		else
		{

		}
	};
	readerFile.onerror = function(errorRead)
	{
		error(errorRead);
	};
};

/* convertToBase64 - END */

//#endregion