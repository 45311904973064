<div id="divBodySignIn" appCheckDevTools>
    <header id="headerSignIn">
        <h1>TSI</h1>
        <span id="spanSubTitle">Technology and Information System</span>
    </header>

    <form [ngClass]="{ 'FormSignInShow' : !_booleanFormSignInEmail, 'FormSignInHide' : _booleanFormSignInEmail }">
        <div class="DivForm">
			<h3>Sign In</h3>
            <fieldset>
				<label for="emailEmail">Email</label>
                <div class="DivInputHorizontalContainer">
					<input type="email" id="emailEmail" name="modelUserEmail" [(ngModel)]="_modelUser.Email" placeholder="input your employee email" >
					<div class="DivFormSingleDropdown DivFormSigninDropdown">
						<ng-select class="NgSelectSinggleDropdown" name="EmailDomain" id="selectEmailDomain"
							[(ngModel)]="_stringEmailDomain"
							[clearable]="false">
							<ng-option [value]="_enumStringConstant.STRING_FIELD_EMAIL_BRINS">{{ _enumStringConstant.STRING_FIELD_EMAIL_BRINS }}</ng-option>
							<ng-option [value]="_enumStringConstant.STRING_FIELD_EMAIL_WORK_BRINS">{{ _enumStringConstant.STRING_FIELD_EMAIL_WORK_BRINS }}</ng-option>
						</ng-select>
					</div>
                    <!-- <label for="emailEmail" class="LabelSuffix">@work.brins.co.id</label> -->
                </div>
            </fieldset>
        </div>
        <input type="button" value="Enter" (click)="callSelectTSIByEmail(false)">
        <div class="DivEmailNotRegistered">
            <p class="PEmailNotRegistered">Email not registered ?
                <a class="AUnderline" (click)="selectByEmailTSI()"> Contact us</a>
            </p>
            <p class="PVersion"> Version {{ _enumStringConstant.STRING_VALUE_APPLICATION_VERSION}} </p>
        </div>
    </form>

    <form [ngClass]="{ 'FormSignInHide' : !_booleanFormSignInEmail, 'FormSignInShow' : _booleanFormSignInEmail }">
        <div class="DivForm">
            <h3>Verify</h3>
            <fieldset>
                <label for="passwordEmailToken1">Email Token</label>
                <div class="DivInputHorizontalContainer">
                    <input type="number" id="passwordEmailToken1" class="InputToken" name="stringEmailToken1" [(ngModel)]="_arrayNumberEmailToken[0]" (keyup)="listenInputToken($event, 0)" (click)="clickInputToken(0);" maxlength="1" max="9" required>
                    <input type="number" class="InputToken" name="stringEmailToken2" [(ngModel)]="_arrayNumberEmailToken[1]" (keyup)="listenInputToken($event, 1)" (click)="clickInputToken(1);" maxlength="1" max="9" required>
                    <input type="number" class="InputToken" name="stringEmailToken3" [(ngModel)]="_arrayNumberEmailToken[2]" (keyup)="listenInputToken($event, 2)" (click)="clickInputToken(2);" maxlength="1" max="9" required>
                    <input type="number" class="InputToken" name="stringEmailToken4" [(ngModel)]="_arrayNumberEmailToken[3]" (keyup)="listenInputToken($event, 3)" (click)="clickInputToken(3);" maxlength="1" max="9" required>
                    <input type="number" class="InputToken" name="stringEmailToken5" [(ngModel)]="_arrayNumberEmailToken[4]" (keyup)="listenInputToken($event, 4)" (click)="clickInputToken(4);" maxlength="1" max="9" required>
                    <input type="number" class="InputToken" name="stringEmailToken6" [(ngModel)]="_arrayNumberEmailToken[5]" (keyup)="listenInputToken($event, 5)" (click)="clickInputToken(5);" maxlength="1" max="9" required>
                </div>
            </fieldset>
        </div>
            <input value="Resend" type="button" (click)="callSelectTSIByEmail(false)">
    </form>
</div>

<div class="DiviframeSignIn">
	<a href="https://www.ssltrust.com.au/security-report?domain=tsi.web.brinesia.app" rel="nofollow" target="new"><img src="https://seals.ssltrust.com.au/report_large.png" style="border: 0px;"></a>

	<iframe src="https://securityscorecard.com/security-rating/badge/brinesia.app" width="256" height="100" frameBorder="0" id="iframeSignIn"></iframe>
</div>